
function recordSession() {
    var tid = null;
    var domain = null;
    var sei = null;
    if (sessionStorage.getItem("transactionId") && sessionStorage.getItem("transactionId") != null) {
        tid = sessionStorage.getItem("transactionId");
    }

    if (sessionStorage.getItem("enrollmentId") && sessionStorage.getItem("enrollmentId") != null) {
        sei = sessionStorage.getItem("enrollmentId");
    }
    else {
        sei = 1;
    }

    domain = window.location.hostname;

    // function activateInspectlet(domain, tid, sei) {
    if (domain != null && tid != null && sei != null) {
        window.__insp = window.__insp || [];
        __insp.push(['wid', 1840577808]);
        (function () {
            function ldinsp() {
                if (typeof window.__inspld != "undefined")
                    return;
                try {
                    window.__inspld = 1;
                    var insp = document.createElement('script');
                    insp.type = 'text/javascript';
                    insp.async = true;
                    insp.id = "inspsync";
                    insp.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://adp.measureone.com/misc/js/mo_tracker.js?d=' + domain + '&t=' + tid + '&s=' + sei + '&wid=1840577808+&r=' + Math.floor(new Date().getTime() / 3600000);
                    var x = document.getElementsByTagName('script')[0];
                    x.parentNode.insertBefore(insp, x);
                }
                catch (err) {
                    console.log("Inspectlet is not working");
                }
            };
            setTimeout(ldinsp, 500);
            document.readyState != "complete" ? (window.attachEvent ? window.attachEvent('onload', ldinsp) : window.addEventListener('load', ldinsp, false)) : ldinsp();
        })();
    }
}
    // }